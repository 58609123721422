var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.submissions)?_c('div',{attrs:{"id":"map"}},[_c('mapbox',{attrs:{"access-token":_vm.$store.state.mapboxToken,"map-options":{
      style: 'mapbox://styles/' + _vm.$store.state.style.id,
      center: [13, 12],
      zoom: 1.6,
      bearing: -6.5, // bearing in degrees
      padding: {
        right: 420
      }
    }},on:{"map-init":_vm.initialized,"map-load":_vm.loaded,"map-move":_vm.updateMapMarkers,"map-zoomend":_vm.updateMapMarkersEnd}}),_c('nav',{staticClass:"filter-group",attrs:{"id":"filter-group"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
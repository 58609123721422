export const towns = [
  {
    name: "Gomari costin",
    coords: [13.1827778, 11.8286111],
    country: "nigeria",
  },
  {
    name: "Abadam City",
    coords: [13.1897222, 11.8275],
    country: "nigeria",
  },
  {
    name: "Dipcharima",
    coords: [13.1952778, 11.8294444],
    country: "nigeria",
  },
  {
    name: "Kaleri",
    coords: [13.1988889, 11.8325],
    country: "nigeria",
  },
  {
    name: "Adamko lo",
    coords: [13.1891667, 11.8297222],
    country: "nigeria",
  },
  {
    name: "Gwozari",
    coords: [13.1888889, 11.8336111],
    country: "nigeria",
  },
  {
    name: "Zannari",
    coords: [13.1930556, 11.8397222],
    country: "nigeria",
  },
  {
    name: "Fulatari",
    coords: [13.1944444, 11.8436111],
    country: "nigeria",
  },
  {
    name: "Simari",
    coords: [13.1977778, 11.8447222],
    country: "nigeria",
  },
  {
    name: "Goni Kachaalari",
    coords: [13.2091667, 11.8580556],
    country: "nigeria",
  },
  {
    name: "Mala kyari",
    coords: [13.2091667, 11.8580556],
    country: "nigeria",
  },
  {
    name: "Chad Basin",
    coords: [13.22, 11.8616667],
    country: "nigeria",
  },
  {
    name: "Muna",
    coords: [13.2411111, 11.8666667],
    country: "nigeria",
  },
  {
    name: "Shukari",
    coords: [13.1880556, 11.8530556],
    country: "nigeria",
  },
  {
    name: "Fariya",
    coords: [13.0183333, 11.8644444],
    country: "nigeria",
  },
  {
    name: "Abbagana ram",
    coords: [13.1563889, 11.8575],
    country: "nigeria",
  },
  {
    name: "Umarari",
    coords: [13.1372222, 11.8694444],
    country: "nigeria",
  },
  {
    name: "Mashamari",
    coords: [13.1844444, 11.8491667],
    country: "nigeria",
  },
  {
    name: "Gwange",
    coords: [13.1763889, 11.8327778],
    country: "nigeria",
  },
  {
    name: "Zajeri",
    coords: [13.1305556, 11.8722222],
    country: "nigeria",
  },
  {
    name: "Gamboru",
    coords: [13.1888889, 11.8555556],
    country: "nigeria",
  },
  {
    name: "Meri",
    coords: [13.1833333, 11.8108333],
    country: "nigeria",
  },
  {
    name: "Bolori",
    coords: [13.1291667, 11.8602778],
    country: "nigeria",
  },
  {
    name: "Ouro Tada",
    coords: [13.77615, 10.7367513],
    country: "cameroon",
  },
  {
    name: "Ldamtsai",
    coords: [13.8070876, 10.7472963],
    country: "cameroon",
  },
  {
    name: "Mokola",
    coords: [13.820095033070572, 10.782816655210496],
    country: "cameroon",
  },
  {
    name: "Mofolde",
    coords: [13.785202643643856, 10.725931200250514],
    country: "cameroon",
  },
  {
    name: "Adebour",
    coords: [11.876448, 13.318360],
    country: "niger",
  },
  {
    name: "Adjidogari",
    coords: [11.939825, 13.248185],
    country: "niger",
  },
  {
    name: "Adjiri",
    coords: [12.973445, 14.044522],
    country: "niger",
  },
  {
    name: "Alaouri",
    coords: [11.987483, 13.200538],
    country: "niger",
  },
  {
    name: "Ambouram",
    coords: [11.967768, 13.246390],
    country: "niger",
  },
  {
    name: "Arimaladi ",
    coords: [10.862320, 13.698192],
    country: "niger",
  },
  {
    name: "Assaga",
    coords: [12.689255, 13.377862],
    country: "niger",
  },
  {
    name: "Awaridi",
    coords: [12.589013, 13.343502],
    country: "niger",
  },
  {
    name: "Bagara",
    coords: [12.594435, 13.293867],
    country: "niger",
  },
  {
    name: "Bara",
    coords: [12.151278, 13.338175],
    country: "niger",
  },
  {
    name: "Barame-tchandi",
    coords: [12.948753, 13.981238],
    country: "niger",
  },
  {
    name: "Birzouweya",
    coords: [12.996040, 14.291595],
    country: "niger",
  },
  {
    name: "Blabrine",
    coords: [10.816810, 13.702182],
    country: "niger",
  },
  {
    name: "Bonegral",
    coords: [13.072080, 14.220220],
    country: "niger",
  },
  {
    name: "Boudouri ",
    coords: [12.487290, 13.265872],
    country: "niger",
  },
  {
    name: "Boulel",
    coords: [11.981508, 13.287157],
    country: "niger",
  },
  {
    name: "Boulongourie",
    coords: [12.636578, 13.338488],
    country: "niger",
  },
  {
    name: "Bouloungou Yakou",
    coords: [12.602020, 13.418053],
    country: "niger",
  },
  {
    name: "Boutti ",
    coords: [11.331490, 13.986573],
    country: "niger",
  },
  {
    name: "Chéri",
    coords: [11.383937, 13.426983],
    country: "niger",
  },
  {
    name: "Chetimari Grema artori",
    coords: [12.597848, 13.339240],
    country: "niger",
  },
  {
    name: "Dala wako",
    coords: [12.021623, 13.277292],
    country: "niger",
  },
  {
    name: "Darsoram",
    coords: [11.930970, 13.306898],
    country: "niger",
  },
  {
    name: "Digargo",
    coords: [12.581855, 13.365183],
    country: "niger",
  },
  {
    name: "Dinkari ",
    coords: [11.179425, 13.598857],
    country: "niger",
  },
  {
    name: "Djabori",
    coords: [12.063882, 13.501222],
    country: "niger",
  },
  {
    name: "Djadjeri ",
    coords: [11.741778, 13.554370],
    country: "niger",
  },
  {
    name: "Djiguindimi",
    coords: [11.970697, 13.265783],
    country: "niger",
  },
  {
    name: "Gadjamé",
    coords: [12.049187, 13.251308],
    country: "niger",
  },
  {
    name: "Gadodo ",
    coords: [12.945950, 13.932555],
    country: "niger",
  },
  {
    name: "Gagamari ",
    coords: [12.421425, 13.203368],
    country: "niger",
  },
  {
    name: "Gaptiari",
    coords: [12.059422, 13.335997],
    country: "niger",
  },
  {
    name: "Garaoua",
    coords: [12.048308, 13.395280],
    country: "niger",
  },
  {
    name: "Gargada (N'gourtoua) ",
    coords: [12.359130, 13.177762],
    country: "niger",
  },
  {
    name: "Garin Dogo",
    coords: [12.770315, 13.413282],
    country: "niger",
  },
  {
    name: "Garin Wanzam",
    coords: [12.897385, 13.629678],
    country: "niger",
  },
  {
    name: "Goudjou",
    coords: [11.815772, 13.452988],
    country: "niger",
  },
  {
    name: "Ibrahimdi ",
    coords: [12.550532, 13.351295],
    country: "niger",
  },
  {
    name: "Issari ",
    coords: [12.287568, 13.642293],
    country: "niger",
  },
  {
    name: "Kabi ",
    coords: [12.445197, 13.284257],
    country: "niger",
  },
  {
    name: "Kadelaboa Canada ",
    coords: [10.903808, 13.700523],
    country: "niger",
  },
  {
    name: "Kadelaboa Koura ",
    coords: [10.912332, 13.721245],
    country: "niger",
  },
  {
    name: "Kangarwa",
    coords: [12.647225, 13.383402],
    country: "niger",
  },
  {
    name: "Karagou",
    coords: [12.974543, 13.993942],
    country: "niger",
  },
  {
    name: "Kargamari ",
    coords: [12.452332, 13.228042],
    country: "niger",
  },
  {
    name: "Kawa ",
    coords: [12.906230, 14.058225],
    country: "niger",
  },
  {
    name: "Kaya",
    coords: [12.666878, 13.331623],
    country: "niger",
  },
  {
    name: "Kaya ",
    coords: [12.487308, 13.395415],
    country: "niger",
  },
  {
    name: "Kayetawa",
    coords: [11.467795, 13.397792],
    country: "niger",
  },
  {
    name: "Kelakam ",
    coords: [11.740637, 13.555188],
    country: "niger",
  },
  {
    name: "Kilawadji",
    coords: [11.719480, 13.348305],
    country: "niger",
  },
  {
    name: "Kindjandi ",
    coords: [12.932502, 13.734848],
    country: "niger",
  },
  {
    name: "Klakoumana",
    coords: [13.093880, 14.232187],
    country: "niger",
  },
  {
    name: "Kosseri kolta ",
    coords: [11.376480, 13.638983],
    country: "niger",
  },
  {
    name: "Kosseri Koura ",
    coords: [11.399422, 13.699082],
    country: "niger",
  },
  {
    name: "Kourssari",
    coords: [12.611377, 13.358402],
    country: "niger",
  },
  {
    name: "Kria",
    coords: [11.879253, 13.388835],
    country: "niger",
  },
  {
    name: "Lari Kournakam (site déplacés)",
    coords: [13.132690, 14.345720],
    country: "niger",
  },
  {
    name: "Lattouaram ",
    coords: [11.218860, 13.631488],
    country: "niger",
  },
  {
    name: "Ligaridi",
    coords: [12.532280, 13.335325],
    country: "niger",
  },
  {
    name: "Liskidi",
    coords: [13.068038, 14.253247],
    country: "niger",
  },
  {
    name: "Maboutou",
    coords: [13.054805, 14.237108],
    country: "niger",
  },
  {
    name: "Malan Boukardi ",
    coords: [12.405133, 13.359733],
    country: "niger",
  },
  {
    name: "Malan Boukardi ",
    coords: [12.691140, 13.399070],
    country: "niger",
  },
  {
    name: "Malan Gamari ",
    coords: [12.527690, 13.315365],
    country: "niger",
  },
  {
    name: "Malan Kournadi ",
    coords: [12.300207, 13.256763],
    country: "niger",
  },
  {
    name: "Maloumdi",
    coords: [12.597535, 13.321702],
    country: "niger",
  },
  {
    name: "Mamari",
    coords: [12.526045, 13.414840],
    country: "niger",
  },
  {
    name: "Mandara Kairam",
    coords: [13.304443, 14.409032],
    country: "niger",
  },
  {
    name: "Mataou",
    coords: [12.559848, 13.339543],
    country: "niger",
  },
  {
    name: "Mellari",
    coords: [12.123117, 13.452355],
    country: "niger",
  },
  {
    name: "Messorodi ",
    coords: [12.383810, 13.323147],
    country: "niger",
  },
  {
    name: "Moumbio",
    coords: [12.957377, 13.945877],
    country: "niger",
  },
  {
    name: "N’Guel Lamido",
    coords: [11.861388, 13.382275],
    country: "niger",
  },
  {
    name: "Nayi",
    coords: [11.974313, 13.384548],
    country: "niger",
  },
  {
    name: "Ngagala",
    coords: [13.023375, 14.174558],
    country: "niger",
  },
  {
    name: "Ngalewa (site déplacés)",
    coords: [12.959662, 14.060900],
    country: "niger",
  },
  {
    name: "N'Garanna ",
    coords: [12.945782, 13.808472],
    country: "niger",
  },
  {
    name: "N'garwa Gana ",
    coords: [12.757530, 13.389780],
    country: "niger",
  },
  {
    name: "Ngortogol",
    coords: [13.014660, 14.157448],
    country: "niger",
  },
  {
    name: "N'guaguam",
    coords: [12.864077, 13.551190],
    country: "niger",
  },
  {
    name: "N'Guel kolo ",
    coords: [12.365643, 13.421432],
    country: "niger",
  },
  {
    name: "Oudi Arabe",
    coords: [12.974707, 14.121588],
    country: "niger",
  },
  {
    name: "Oudi Peulh",
    coords: [12.980055, 14.128015],
    country: "niger",
  },
  {
    name: "Rakka",
    coords: [11.771515, 13.494927],
    country: "niger",
  },
  {
    name: "Saleri",
    coords: [11.753647, 13.397723],
    country: "niger",
  },
  {
    name: "Sayam centre ",
    coords: [12.505733, 13.622705],
    country: "niger",
  },
  {
    name: "Tachan rogo",
    coords: [11.905340, 13.332217],
    country: "niger",
  },
  {
    name: "Tamsougoua",
    coords: [11.911678, 13.504180],
    country: "niger",
  },
  {
    name: "Tattoukouttou",
    coords: [11.646632, 13.339912],
    country: "niger",
  },
  {
    name: "Tchagamari",
    coords: [11.737725, 13.309155],
    country: "niger",
  },
  {
    name: "Yambal",
    coords: [13.095442, 14.281975],
    country: "niger",
  },
  {
    name: "Yamkone",
    coords: [11.894308, 13.248630],
    country: "niger",
  }
];

import Vue from 'vue';
import Vuex from 'vuex';
import * as Axios from 'axios'
import { towns } from '@/constants/towns'
import { KOBO_API_URL } from "@/constants/kobo";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    AUTH_TOKEN: `Token ad9e22beb3829f684544127d26c8a3dc9e1295b6`,
    mapboxToken: `pk.eyJ1IjoicGxjYXJ0b25nIiwiYSI6ImNrN25pbTN4bDAycXEzZnM4a212M3k1dWkifQ.mfCBz7pz5g7ykUXaeNy13A`,
    FORMS_UID: {
      nigeria:  'aJn6r5KosffwG4S4exSzrJ',
      niger:    'akY29uvotxjroqJPvwxd6J',
      cameroon: 'aubaACN3DGxZ7TGUThEfcp'
    },
    towns,
    lists: {
      ownership: [
        'government',
        'private',
      ],
      adminLevels: [
        'community',
        'department',
        'state',
        'regional',
        'national',
        'international'
      ],
      activities: [
        'educ',
        'nutrition',
        'srh',
        'hlt',
        'protection',
        'eco',
        'conflictResolution',
        'advocacy',
        'childhoodDev',
        'gender',
        'wash',
        'ict'
      ]
    },
    KOBO_USERNAME: 'youthprojectlcr',
    MAX_COUNT: null,
    style: {
      id: 'plcartong/ckihfzfro6i8b19s2zwgr3jdx/draft',
      name: "Plan",
      img: require('../assets/img/basemap-plan.png')
    },
    submissions: null,
    selectedPlaceData: null,
    translations: null,
    map: null,
    geocoder: null,
    labelPopup: null,
    mapLoaded: false,
    lang: 'en',
    fromDate: +(new Date("2019-02-26")).getTime()/1000,
    toDate: +(new Date("2023-02-26")).getTime()/1000,
    selectedCategory: null,
    selectedTown: null,
    selectedSubcategory: '',
    selectedCountry: null,
    categories: {
      'education': { icon: 'education', shown: true, color: "#00843d", subcategories: []},
      'health': { icon: 'health', shown: true, color: "#0072ce", subcategories: []},
      'youth-organizations': { icon: 'youth-organizations', shown: true, color: "#e17800", subcategories: []}
    },
    newContent: false
  },
  mutations: {
    updateSubmissions (state, submissions) {
      state.submissions = submissions
    },
    updateTranslations (state, translations) {
      state.translations = translations
    },
    updateFromDate (state, fromDate) {
      state.fromDate = fromDate
    },
    updateToDate (state, toDate) {
      state.toDate = toDate
    },
    updateMap (state, map) {
      state.map = map
    },
    updateGeocoder (state, geocoder) {
      state.geocoder = geocoder
    },
    updateSelectedPlaceData (state, placeData) {
      state.selectedPlaceData = placeData
    },
    updateLabelPopup (state, labelPopup) {
      state.labelPopup = labelPopup
    },
    updateMapLoaded (state) {
      state.mapLoaded = true
    },
    updateLang (state, lang) {
      state.lang = lang
    },
    updateStyle (state, style) {
      state.style = style
    },
    updateSelectedCategory (state, category = null) {
      state.selectedCategory = state.selectedCategory === category ? null : category

      Object.keys(state.categories).forEach(cat => {
        let show = (state.selectedCategory === cat || state.selectedCategory === null) ? 'visible' : 'none'
        if (typeof state.map.getLayer('poi-' + cat) !== 'undefined') {
          state.map.setLayoutProperty(
            'poi-' + cat,
            'visibility', show
          );
        }
      })
    },
    updateSelectedSubcategory (state, selectedSubcategory = null) {
      state.selectedSubcategory = selectedSubcategory
    },
    updateSelectedTown (state, selectedTown = null) {
      state.selectedTown = selectedTown
    },
    updateSelectedCountry (state, country = null) {
      state.selectedCountry = state.selectedCountry === country ? null : country
      state.selectedTown = null
    },
    updateNewContent(state, newContent) {
      state.newContent = newContent
    }
  },

  getters: {
    town: function (state) {
      return state.towns.find(town => town.name === state.selectedTown);
    }
  },
  actions: {

    async getData(){
      try {
        let data = []

        const PROXY_FOR_CORS = ""
        const TRANSLATION_URL = KOBO_API_URL + this.state.FORMS_UID.cameroon + "/deployment/";
        const koboReqOptions = {
          method: 'get',
          url: PROXY_FOR_CORS + TRANSLATION_URL,
          params: { format: 'json' },
          headers: {
            Authorization: this.state.AUTH_TOKEN
          }
        }
        await Axios(koboReqOptions).then(async (response) => {
          let translations = {};
          response.data.asset.content.choices.forEach(choice => {
            let name = choice.name;
            translations[name] = choice;
          })
          this.commit('updateTranslations', translations);

          for (const [form_uid] of Object.entries(this.state.FORMS_UID)) {

            const URL = KOBO_API_URL + this.state.FORMS_UID[form_uid] + "/data.json"

            koboReqOptions.url = PROXY_FOR_CORS + URL
            await Axios(koboReqOptions).then(async (response) => {

              let defaultRow = {
                coords: [],
                id: null,
                country: form_uid,
                _submission_time: null,
                icon: 'no category',
                label: 'No label',
                description: null,
                image: null,
                subcategory: null,
                type: {
                  en: '',
                  fr: '',
                },
                data: null
              };

              for (let d of response.data.results) {
                // Show only validated submissions, where is consent is given, and gps not null
                if (d._validation_status.uid === 'validation_status_approved' && d.consent !== 'no' && d._geolocation[0] != null) {

                  // Check the category is set
                  let serviceType = d['groupConsent/groupMapDisplay/serviceType']
                  if (typeof serviceType != 'undefined') {
                    let row = Object.assign({}, defaultRow)
                    let type = ""
                    row.id = +d._id
                    row._submission_time = toTimestamp(d._submission_time)
                    row.coords = d._geolocation.reverse()
                    row.label = d['groupConsent/groupMapDisplay/name']

                    if (d._attachments.length > 0) {
                      row.image = d?._attachments[0]?.download_small_url ?? null;
                      if (row.image !== null) {
                        row.image = row.image.split('?')[0]
                      }
                    }

                    row.data = d;

                    if (serviceType === "health") {
                      row.icon = serviceType
                      type = d['groupConsent/groupMapDisplay/subTypeHlt']
                    } else if (serviceType === "youthParticipation") {
                      row.icon = 'youth-organizations'
                      type = d['groupConsent/groupMapDisplay/subTypeOrganization']
                    } else if (serviceType === "education") {
                      row.icon = serviceType
                      type = d['groupConsent/groupMapDisplay/subTypeEduc']
                    }

                    if (!this.state.categories[row.icon].subcategories.includes(type)) {
                      this.state.categories[row.icon].subcategories.push(type)
                    }

                    row.subcategory = type

                    // Handles translations
                    if (translations[type] != undefined) {
                      row.type.en = translations[type].label[0];
                      row.type.fr = translations[type].label[1];
                    } else {
                      row.type.en = type;
                      row.type.fr = type;
                    }

                    data.push(JSON.parse(JSON.stringify(row)))
                  }
                }
              }
            })
          }
        });

        this.commit('updateSubmissions', JSON.parse(JSON.stringify(data)));

      } catch (e) {
        console.log(e)
        // context.commit("setDataLoadingError", e)
      }
    }
  }
});

function toTimestamp(strDate){
  var datum = Date.parse(strDate);
  return datum/1000;
}
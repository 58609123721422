<template>
  <nav id="Country" class="Country">
    <div
      class="Country__item"
      v-for="country in Object.keys($store.state.FORMS_UID)" :key="country"
      :data-selected="selected(country)">
      <input @click="updateFilter(country)" checked name="country" type="radio" :id="'poi-' + country" >
      <label :for="'poi-' + country">
        <div class="Label" >{{ words[$store.state.lang].country[country] }}</div>
      </label>
    </div>
  </nav>
</template>

<script>
import { words } from '@/constants/lang'

export default {
  name: 'CountryPicker',
  data: () => ({
    words
  }),
  methods: {
    selected(country) {
      return this.$store.state.selectedCountry === country;
    },
    updateFilter(country) {
      this.$store.commit("updateSelectedCountry", country)
    }
  }
}
</script>

<style lang="less">
@import "../assets/less/var";

.Country {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1rem;
  color: #fff;

  .Country__item {
    background: #fafafa;
    flex: 1 0 auto;
    max-height: 29px;
    border: solid 1.5px #000;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    flex-flow: row nowrap;
    @border-w: 0.75px;

    label {
      padding: .25rem .5rem;
      min-height: 27px;
    }

    &:nth-child(1) { border-radius: @rad-box 0 0 @rad-box; border-right-width: @border-w; }
    &:nth-child(2) { border-radius: 0 0 0 0; border-left-width: @border-w; border-right-width: @border-w; }
    &:nth-child(3) { border-radius: 0 @rad-box @rad-box 0; border-left-width: @border-w; }

    &[data-selected] {
      background: #333;

      .Label {
        color: white !important;
      }
    }

    input[type='radio']:first-child + label {
      border-radius: 3px 3px 0 0;
    }

    label:last-child {
      border-radius: 0 0 3px 3px;
      border: none;
    }

    input[type='radio'] {
      display: none;

      & + label {
        display: flex;
        flex-flow: row nowrap;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.25);

        > .Label {
          text-transform: capitalize;
          color: #333;
          font-size: 1em;
          font-family: @font-primary;
          font-weight: 100;
          flex: 1 0 auto;
          display: inline-block;
        }
      }
    }
    * {
      transition: all .12s ease-in;
    }
    input[type='checkbox']:checked + label {
      .Label {
        color: #333;
      }
    }
  }
}
</style>

<template>
  <div :class="$store.state.style.name === 'Satellite' ? 'App App--satellite' : 'App'" :lang="this.$store.state.lang">
    <div id="geoapp" :class="$store.state.submissions === null ? 'Blur' : ''">
      <div class="Title">
        <h1 v-html="this.words[this.$store.state.lang].general.Title"></h1>
        <Translator/>
      </div>
      <Map/>
      <BasemapPicker/>
      <InfoPanel/>
      <!-- <YearRange/> -->
      <Sponsors/>
    </div>
    <Loader/>
    <ContentRefresh v-show="$store.state.newContent"/>
  </div>
</template>

<script>
import { words } from '@/constants/lang'
import Map from './components/Map.vue'
import InfoPanel from './components/InfoPanel.vue'
import Translator from './components/Translator.vue'
import Loader from './components/Loader.vue'
import BasemapPicker from './components/BasemapPicker.vue'
// import YearRange from './components/YearRange.vue'
import Sponsors from './components/Sponsors.vue'
import ContentRefresh from './components/ContentRefresh.vue'

export default {
  name: 'App',
  components: {
    Map,
    BasemapPicker,
    // YearRange,
    Loader,
    Translator,
    Sponsors,
    ContentRefresh,
    InfoPanel
  },
  data: () => ({
    words,
  }),
  mounted(){
    this.$store.dispatch('getData')
  }
}
</script>


<style lang="less">
  @import "./assets/less/var";

  * {
    font-family: @font-secondary;
  }
  html, body {
    background-color: #ddd;
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .App {
    height: 100%;
  }
  .BasemapPicker {
    align-self: flex-end;
  }
  #geoapp {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;

    &.Blur {
      filter: blur(10px);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 20vh;
      background: linear-gradient(180deg, rgba(255,255,255,.7), transparent);
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 20vh;
      background: linear-gradient(0deg, rgba(255,255,255,.7), transparent);
      z-index: 1;
    }
    .Title {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      align-self: flex-start;
      z-index: 1;
      margin: 1rem 2rem;
      top: 0;
      position: absolute;
      pointer-events: none;
      user-select: none;
      max-width: calc(100vw - @dim-info-panel);

      h1 {
        font-size: 1.8em;
        margin: 0;
        line-height: 1.5em;

        text-transform: uppercase;
        color: @color-primary;
        font-weight: 300;

        &, * {
          pointer-events: none;
          user-select: none;
          font-family: @font-primary;
        }
      }
      .Translator {
        z-index: 1;
        pointer-events: initial;
        // user-select: none;
      }
    }

    .Sponsors {
      z-index: 2;
      transform-origin: bottom right;
    }
    .HistogramSlider {
      align-self: flex-start;
      margin: 0 2rem 1rem 2rem;
      z-index: 2;

      &[no-data] {
        *[class^="vue-histogram-slider-bar-vue-histogram-"] {
            height: 0 !important;
        }
      }
      .irs--round .irs-from, .irs--round .irs-single, .irs--round .irs-to {
        font-size: 12px;
      }
      .irs-grid {
        z-index: -1;

        .irs-grid-pol {
          background: rgba(0,0,0,0.4) !important;
        }
      }
      .irs--round .irs-handle {
        box-shadow: 0 1px 3px rgb(0,0,0,0.3);
      }
    }
  }

  @media screen and (max-width: 640px) {
    #geoapp {

      .BasemapPicker {
        top: 140px;
      }

      &::before {
        background: linear-gradient(180deg, rgba(255, 255, 255, 1), transparent);
        height: 25vh;
      }

      .Sponsors {
        display: none;
      }
      .Title {
        margin: 15px;
        align-items: flex-start;

        h1 {
          margin-top: 0;
          font-size: 1.5em;
        }

        .Translator .Translator__list {
          margin: 0;
          .Translator__item {
            font-size: 0.7em;
          }
        }
      }

      .mapboxgl-ctrl-top-right {
        top: 32px;
      }

      .Label::before {
        content: none !important;
      }
      .Legend {
        margin-bottom: 10px;

        .Legend__item input[type='checkbox'] + label > .Label {
          font-size: 0.85em;
          padding: 0 6px;
        }
        .Legend__item input[type='checkbox'] + label .Image {
          padding: 8px 8px 4px 8px;

          img {
            width: 14px;
          }
        }
      }
    }
  }

  // Edit app style if satellite imagery is used in the map
  .App.App--satellite #geoapp {
    .InfoPanel {
      box-shadow: -5px 0 25px -5px rgba(0,0,0,.5)
    }
    &::before {
      background: linear-gradient(180deg, rgba(0,0,0,0.5),transparent);
    }
    &::after {
      background: linear-gradient(0deg,rgba(0,0,0,0.5),transparent);
    }
    .Title h1 {
      &, b {
        font-weight: 100 !important;
        color: white;
      }
    }
    .Translator .Translator__list .Translator__item {
        color: white !important;

        .Translator__item:not(:last-child) {
          border-right-color: white !important;
        }
    }
    .Sponsors__disclaimer {
        color: rgba(255,255,255,.78);
    }
    .mapboxgl-ctrl-attrib a {
      color: rgba(255,255,255,0.75);
    }
    .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
      background-color: rgba(0,0,0,0.5);
    }


    @media screen and (max-width: 640px) {
      #geoapp:before {
          background: linear-gradient(180deg ,rgba(0,0,0,.37),transparent);
      }
    }

    .mapboxgl-popup-content {
        box-shadow: 0 5px 21px -3px rgba(0,0,0,.5);
    }
  }
  .mapboxgl-ctrl-top-right,
  .mapboxgl-ctrl-bottom-right,
  .Sponsors {
    right: @dim-info-panel;
  }
</style>
